import {
  Config,
  SurveyData,
  SurveyMetadata,
  SurveyParams,
} from './SurveyCollector';
import { cleanSurveyData, saveSurveyData } from './localStore';
import { createSurveyUrl } from './surveyUrl';

export const sendData = (
  config: Config,
  surveyParams: SurveyParams,
  surveyMetadata: SurveyMetadata,
  surveyData: SurveyData
): Promise<SurveyData> => {
  const url = createSurveyUrl(config.REACT_APP_API_URI, surveyParams);

  return postData(url, {
    ...surveyData,
    timestamp: new Date().toISOString(),
    surveyLanguage: surveyMetadata.surveyLanguage,
  }).then(
    () => {
      cleanSurveyData(surveyParams);

      return surveyData;
    },
    async (err) => {
      console.error(err);

      const data = await err.json();

      if (
        data &&
        data.errors &&
        data.errors[0] &&
        (data.errors[0].code === 'VALIDATION_ERROR' ||
          data.errors[0].code === 'WRONG_STATUS')
      ) {
        location.reload();
      }

      if (
        !config.REACT_APP_PREVIEW_MODE &&
        (!data ||
          !data.errors ||
          !data.errors[0] ||
          data.errors[0].code !== 'VALIDATION_WARNING')
      ) {
        saveSurveyData(surveyParams, surveyData);
      }

      throw surveyData;
    }
  );
};

function postData(url, data) {
  return fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  }).then((response) => {
    if (response.ok) {
      return response;
    }

    throw response;
  });
}
