import { anyPass, map, filter, head, path, pathOr, pipe, propEq } from 'ramda';
import {
  flatQuestionTreeWithPaths,
  isEscape,
  isPage,
  isPersonalAgreement,
} from 'xperience-model-management';
import {
  getParentSectionPath,
  isPathNested,
} from '../../surveyCollector/pathFunctions';

const getBeforeExitQuestionOption = pathOr(false, [
  'hookOptions',
  'beforeExitQuestion',
]);

export function getBeforeExitPaths(inlineDefinition) {
  const flattenSurvey = flatQuestionTreeWithPaths(inlineDefinition);
  const escapeQuestions = filter(
    anyPass([isEscape, isPersonalAgreement]),
    flattenSurvey
  );
  return map(escapeQuestion => {
    const beforeExitQuestionOption = getBeforeExitQuestionOption(
      escapeQuestion
    );

    if (!beforeExitQuestionOption) {
      return null;
    }

    const beforeExitQuestion = pipe(
      filter(propEq('id', beforeExitQuestionOption)),
      head
    )(flattenSurvey);

    if (!beforeExitQuestion) {
      console.warn(
        'Can not find beforeExitQuestion - ',
        beforeExitQuestionOption
      );
      return null;
    }

    const questionPath = beforeExitQuestion.path;
    let screenPath = questionPath;

    if (isPathNested(questionPath)) {
      const parentPath = getParentSectionPath(questionPath);
      const parent = path(parentPath, inlineDefinition);
      if (isPage(parent)) {
        screenPath = parentPath;
      }
    }

    return {
      escapeQuestionPath: escapeQuestion.path,
      jumpQuestionPath: questionPath,
      jumpScreenPath: screenPath,
    };
  }, escapeQuestions);
}
