import { SurveyParams } from './SurveyCollector';
import { fromPairs, isEmpty, toPairs } from 'ramda';

export type UrlSurveyParams = { surveyParams: SurveyParams; searchParams: any };

export const acquireParamsFromUrl = (currentUrl: string): UrlSurveyParams => {
  const url = new URL(currentUrl);

  const surveyId = url.pathname.slice(1);
  if (isEmpty(surveyId)) {
    throw new Error('Unknown survey id');
  }

  const surveyParams: SurveyParams = {
    surveyId,
  };

  if (url.searchParams.has('lang')) {
    surveyParams.surveyLanguage = url.searchParams.get('lang') as string;
  }

  return {
    surveyParams,
    // @ts-ignore
    searchParams: fromPairs(Array.from(url.searchParams)),
  };
};

export const createSurveyUrl = (API_URI, surveyParams, searchParams = {}) => {
  const urlSearchParams = new URLSearchParams('');
  const paramsAsPars = toPairs(searchParams);

  if (surveyParams.surveyLanguage) {
    paramsAsPars.unshift(['lang', surveyParams.surveyLanguage]);
  }

  paramsAsPars.forEach(([key, value]) =>
    urlSearchParams.set(key, value as string)
  );

  const searchParamsAsString = urlSearchParams.toString();

  return [
    `${API_URI}/survey/${surveyParams.surveyId}`,
    searchParamsAsString ? `?${searchParamsAsString}` : '',
  ].join('');
};
