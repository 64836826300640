import { Config } from './surveyCollector/SurveyCollector';
import { assoc } from 'ramda';

export function loadConfig(): Promise<Config> {
  return fetch('./config.json')
    .then(response => response.json())
    .then(config => {
      let previewMode = false;
      // @ts-ignore
      window.appInfo = () => {
        console.info({
          lastCommitCollector: process.env.REACT_APP_GIT_COMMIT,
          xperienceModelManagement: process.env.REACT_APP_XMM,
          buildInfo: config.REACT_APP_BUILD_INFO,
        });
      };

      if (config.REACT_APP_PREVIEW_MODE_REGEX_URI) {
        try {
          previewMode = RegExp(config.REACT_APP_PREVIEW_MODE_REGEX_URI).test(
            location.href
          );
          if (previewMode) {
            console.warn('Preview mode is activated');
          }
        } catch (e) {
          console.error(e);
        }
      }
      return assoc('REACT_APP_PREVIEW_MODE', previewMode, config);
    });
}
