import { BehaviorSubject } from 'rxjs';
import { scan, share, shareReplay, startWith } from 'rxjs/operators';
import { RedirectItems } from '../elementTypes/ElementTypeInterfaces';
import {
  SurveyMetadata,
  SurveyScreen,
} from '../surveyCollector/SurveyCollector';

export enum StateType {
  Loading = 'Loading',
  FillInBlocked = 'FillInBLocked',
  ScreenProcessing = 'ScreenProcessing',
  ScreenDisplayed = 'ScreenDisplayed',
  ConnectionErrorDisplayed = 'ConnectionErrorDisplayed',
  FinalScreenDisplayed = 'FinalScreenDisplayed',
}

export type StateLoading = { type: StateType.Loading };

export type StateFillInBLocked = {
  type: StateType.FillInBlocked;
  code?: number;
  reason: string;
  redirect?: RedirectItems;
  redirectLink?: string;
  redirectSeconds?: number;
};

export type StateScreenProcessing = { type: StateType.ScreenProcessing };

export type StateScreenDisplayed = {
  type: StateType.ScreenDisplayed;
  screen: SurveyScreen;
  surveyMetadata: SurveyMetadata;
};

export type StateConnectionErrorDisplayed = {
  type: StateType.ConnectionErrorDisplayed;
};

export type StateFinalScreenDisplayed = {
  type: StateType.FinalScreenDisplayed;
  screen: SurveyScreen;
};

export const loading = (): StateLoading => ({
  type: StateType.Loading,
});

export const fillInBlocked = (
  reason: string,
  code?: number,
  redirect?: RedirectItems,
  redirectLink?: string,
  redirectSeconds?: number
): StateFillInBLocked => ({
  type: StateType.FillInBlocked,
  code,
  reason,
  redirect,
  redirectLink,
  redirectSeconds,
});

export const screenProcessing = (): StateScreenProcessing => ({
  type: StateType.ScreenProcessing,
});

export const screenDisplayed = (
  screen: SurveyScreen,
  surveyMetadata: SurveyMetadata
): StateScreenDisplayed => ({
  type: StateType.ScreenDisplayed,
  screen,
  surveyMetadata,
});

export const connectionErrorDisplayed = (): StateConnectionErrorDisplayed => ({
  type: StateType.ConnectionErrorDisplayed,
});

export const finalScreenDisplayed = (
  screen: SurveyScreen
): StateFinalScreenDisplayed => ({
  type: StateType.FinalScreenDisplayed,
  screen,
});

export type State =
  | StateLoading
  | StateFillInBLocked
  | StateScreenProcessing
  | StateScreenDisplayed
  | StateConnectionErrorDisplayed
  | StateFinalScreenDisplayed;

export const transition$ = new BehaviorSubject<State>(loading());
const transition = (state: State) => {
  transition$.next(state);
};

export const getLastTransition = () => transition$.getValue();

export const state$ = transition$.pipe(
  scan((oldState, newState) => newState),
  startWith(loading()),
  shareReplay(1)
);

export const goToLoading = () => transition(loading());

export const goToFillInBlocked = (reason: string, code?: number) =>
  transition(fillInBlocked(reason, code));

export const goToScreenProcessing = () => transition(screenProcessing());

export const goToScreenDisplayed = (
  screen: SurveyScreen,
  surveyMetadata: SurveyMetadata
) => transition(screenDisplayed(screen, surveyMetadata));

export const displayConnectionError = () =>
  transition(connectionErrorDisplayed());

export const goToFinalScreenDisplayed = (screen: SurveyScreen) =>
  transition(finalScreenDisplayed(screen));
