import * as React from 'react';
import { EscapeQuestionProps } from './ElementTypeInterfaces';
import EscapeQuestionDialog from './EscapeQuestionDialog';
import EscapeQuestionBasic from './EscapeQuestionBasic';

const EscapeQuestion: React.FunctionComponent<EscapeQuestionProps> = (
  props
) => {
  const {
    saveDialogText = 'Thank you. Your answers have been saved. You can return to filling the questionnaire any time, until the questionnaire expires.',
    value,
    onChange,
    ...otherProps
  } = props;

  return (
    <>
      <EscapeQuestionDialog
        saveDialogText={saveDialogText}
        isOpen={value === 2}
        onClose={onChange as () => void}
      />
      <EscapeQuestionBasic {...otherProps} onChange={onChange} />
    </>
  );
};

export default EscapeQuestion;
