import * as React from 'react';

import { makeStyles, Theme, Typography } from '@material-ui/core';
import OptOutModal from './OptOutModal';
import { IntroProps } from './ElementTypeInterfaces';

const useStyles = makeStyles((theme: Theme) => ({
  optOutText: {
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: 320
    },
  }
}));

type OptOutProps = {
  openOptOutModal: boolean;
  onOptOutLinkClick: (event: any) => void;
  onCancelClickHandler: () => void;
} & Required<Pick<IntroProps, 'optOut'>>;

const OptOut: React.FunctionComponent<OptOutProps> = ({
                                                        optOut,
                                                        onOptOutLinkClick,
                                                        openOptOutModal,
                                                        onCancelClickHandler,
                                                      }) => {
  const classes = useStyles();

  let optOutText: any = optOut.unsubscribedText;
  if (!optOut.status) {
    const EXPRESSION_REGEX = /(\$\{\ *linkText\ *\})/;
    const Link = (
      <a href="#" key="link" onClick={onOptOutLinkClick}>
        {optOut.linkText}
      </a>
    );
    optOutText = optOut.text.split(EXPRESSION_REGEX);
    optOutText[1] = Link;
  }

  return (
    <div className={classes.optOutText}>
      <Typography variant="caption" color="inherit" data-test-id="opt-out-text"
      >
        {optOutText}
      </Typography>
      <OptOutModal
        modalInfo={optOut.modalInfo}
        modalCancel={optOut.modalCancel}
        onCancelClickHandler={onCancelClickHandler}
        openOptOutModal={openOptOutModal}
        modalOk={optOut.modalOk}
        link={optOut.link}
      />
    </div>
  );
};

export default OptOut;
