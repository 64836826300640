import { format as dateFormat } from 'date-fns';
import {
  always,
  apply,
  assocPath,
  cond,
  curry,
  join,
  map,
  pipe,
  prop,
  propEq,
  toLower,
  toUpper,
} from 'ramda';
import { isArray, isString } from 'ramda-adjunct';
import {
  ScreenElement,
  TextExpression,
  TextExpressionDefinitionOperation,
  Values,
} from './SurveyCollector';

const dateFormatter = (date, format?) => {
  if (!date) {
    return '';
  }

  return format
    ? dateFormat(new Date(date), format)
    : new Date(date).toLocaleDateString();
};

const spaceRegexp = new RegExp(String.fromCharCode(0x20), 'g');
const nonBreakingSpace = String.fromCharCode(0xa0);

function replaceSpaceWithNonBreakingSpace(stringWithSpace = '') {
  return stringWithSpace.replace(spaceRegexp, nonBreakingSpace);
}

export const operationMapping = {
  dateFormatter: pipe(dateFormatter, replaceSpaceWithNonBreakingSpace),
  uppercase: toUpper,
  lowercase: toLower,
};

const concatAll = (...all) => join('', all);

const isTypeVariable = propEq('type', 'variable');
const isTypeFunction = propEq('type', 'function');

const topLevelItemMapping = cond([
  [isString, always],
  [
    isTypeVariable,
    (arg) => (variables) =>
      // @ts-expect-error fix arg type
      `<span dir="ltr">${prop(arg.value, variables)}</span>`,
  ],
  [
    isTypeFunction,
    (arg) => (variables) =>
      `<span dir="ltr">${operationToFunction(arg)(variables)}</span>`,
  ],
  [
    isArray,
    (args) => operationToExpressionFactory(concatAll, { args, topLevel: true }),
  ],
]);

const itemMapping = cond([
  [isString, always],
  // @ts-expect-error FIX types
  [isTypeVariable, (arg) => prop(arg.value)],
  [isTypeFunction, (arg) => operationToFunction(arg)],
  [isArray, (args) => operationToExpressionFactory(concatAll, { args })],
]);

const operationToExpressionFactory = curry(
  (func: (arg0: {}, ...args: {}[]) => {}, { args, topLevel = false }) => {
    const mappingFunction = topLevel ? topLevelItemMapping : itemMapping;
    const argsFunctions = map(mappingFunction, args);

    return (variables) => {
      const argsValues = map(
        (argsFunction) => argsFunction(variables),
        argsFunctions
      );

      return apply(func, argsValues);
    };
  }
);

const operationAsFunctions = map<any, any>(
  operationToExpressionFactory,
  operationMapping
);

const operationToFunction = (arg) =>
  pipe<
    TextExpressionDefinitionOperation,
    keyof typeof operationMapping,
    any,
    any
  >(
    prop('operation'),
    (operationName) => prop(operationName, operationAsFunctions),
    (operationAsFunction) => operationAsFunction(arg)
  )(arg);

export const textExpressionToFunction = (
  textExpression: TextExpression
): ((currentVariables: Values) => string) =>
  topLevelItemMapping(textExpression);

export const imagePathExpressionToFunction = (
  imagePath: TextExpression
): ((currentVariables: Values) => string) =>
  // @ts-expect-error FIX types
  itemMapping(imagePath);

export const processOptOut = ({ optOutLink, optOut = false }: Values) => (
  screenElement: ScreenElement
) => {
  return assocPath(
    ['componentProps', 'optOut'],
    {
      ...screenElement.optOut,
      link: optOutLink,
      status: optOut,
    },
    screenElement
  );
};
