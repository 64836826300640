import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
  })
);

const SurveyLoading: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  );
};

export default SurveyLoading;
