import * as React from 'react';
import {
  OutroProps,
  RedirectItems,
} from '../../elementTypes/ElementTypeInterfaces';
import Outro from '../../elementTypes/Outro';

type FillInBlockedProps = {
  code?: number;
  reason: string;
  redirectLink?: string;
  redirect?: RedirectItems;
  redirectSeconds?: number;
};

export const FillInBlocked: React.FunctionComponent<FillInBlockedProps> = (
  props
) => {
  return (
    <Outro
      text={props.reason}
      redirect={props.redirect}
      redirectLink={props.redirectLink}
      redirectSeconds={props.redirectSeconds}
    />
  );
};
