import {
  DataCollectionType,
  ScreenMove,
  SurveyScreen,
  Values,
} from './SurveyCollector';
import {
  getElementsIds,
  getVisibleElementsIds,
  isFinalScreen,
} from './screenProcessing';
import {
  assoc,
  complement,
  equals,
  filter,
  isEmpty,
  map,
  mergeRight,
  omit,
  pick,
  pipe,
  propSatisfies,
  when,
} from 'ramda';

export const filterMoveRequestToSave = (move: ScreenMove) =>
  move.source !== 'skipEmptyScreen';

export const moveToDataCollectionType = (
  move: ScreenMove
): DataCollectionType =>
  move.sendData || move.moveDirection === 'next'
    ? 'collectedData'
    : 'temporaryData';

export const getScreenValuesFor = (
  dataCollectionType: DataCollectionType,
  screen: SurveyScreen
): Values => {
  return when(
    () => dataCollectionType === 'collectedData',
    pick(getVisibleElementsIds(screen)),
    screen.values
  );
};

export type ScreenValuesChange = {
  type: DataCollectionType;
  questionIds: string[];
  visibleQuestionIds: string[];
  values: Values;
};

export const createScreenValuesChange = (
  type: DataCollectionType,
  screen: SurveyScreen
): ScreenValuesChange => ({
  type,
  questionIds: getElementsIds(screen),
  visibleQuestionIds: getVisibleElementsIds(screen),
  values: screen.values,
});

export const toCollectedData = (valuesChange: ScreenValuesChange): Values => {
  if (valuesChange.type === 'collectedData') {
    return mergeRight(
      pick(valuesChange.visibleQuestionIds, valuesChange.values),
      pipe(
        omit(valuesChange.visibleQuestionIds),
        map<Values, Values>((v) => undefined)
      )(valuesChange.values)
    );
  } else {
    return valuesChange.questionIds.reduce(
      (values, key) => assoc(key, undefined, values),
      {}
    );
  }
};

export const toTemporaryData = (valuesChange: ScreenValuesChange): Values =>
  valuesChange.values;

export const mergeAndCleanEmptyData = pipe<Values, Values, Values>(
  mergeRight,
  filter((v) => v !== undefined)
);

export const prepareDataToSend = (screen, collectedData, events) => ({
  surveyStatus: isFinalScreen(screen) ? 'FINISHED' : 'IN_PROGRESS',
  collectedData,
  events,
});

export const sendDataComparator = equals;
export const filterDataToSend = propSatisfies(
  complement(isEmpty),
  'collectedData'
);
