import * as React from 'react';
import ScreenElement from '../components/ScreenElement';
import { SurveyScreen } from '../../surveyCollector/SurveyCollector';

export type SurveyFinalScreenProps = {
  screen: SurveyScreen;
};

export const ShowFinalScreen: React.FunctionComponent<SurveyFinalScreenProps> = (
  props
) => {
  const screenElements = props.screen.elements.map((element, index) => {
    return <ScreenElement key={index} {...element} hideMainText />;
  });

  return <div>{screenElements}</div>;
};
