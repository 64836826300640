import * as React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import classNames from 'class-names';
import { always, groupBy, isEmpty, pipe, when } from 'ramda';
import { Choice, GenderImageProps, GenderProps } from './ElementTypeInterfaces';
import male from './gender-images/male.png';
import female from './gender-images/female.png';
import { ChoiceButton } from './Rating';

const genderValuePictures = {
  1: male,
  2: female,
};

export const genderImageMapping = {
  ORDER: 'order',
  VALUE: 'value',
};

const useStyles = makeStyles((theme: Theme) => ({
  choice: {
    margin: `${theme.spacing()}px 0`,
    textTransform: 'none',
  },
  choiceSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: '#4ba82e20',
  },
  characters: {
    margin: theme.spacing(),
  },
  image: {
    cursor: 'pointer',
    backgroundColor: '#9B9B9B',
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  imageSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  item: {
    marginLeft: 20,
    marginRight: 20,
  },
}));

const PureGenderImage: React.FunctionComponent<GenderImageProps> = ({
  index,
  value,
  title,
  choiceValue,
  onChange,
}) => {
  const selected = choiceValue === value;
  const picture = genderValuePictures[index];
  const dataTestButtonTitle = `genderButton${title}`;
  const classes = useStyles();

  const onClick = () => {
    onChange(choiceValue);
  };

  return (
    <img
      data-test-id={dataTestButtonTitle}
      onClick={onClick}
      className={classNames(classes.image, {
        [classes.imageSelected]: selected,
      })}
      src={picture}
      alt={title}
      title={title}
    />
  );
};

const choiceToGroupName = (choice: Choice) => {
  return genderValuePictures[choice.value] ? 'image' : 'other';
};

const goupChoices = pipe(
  groupBy(choiceToGroupName),
  when(
    isEmpty,
    always({
      image: [],
      other: [],
    })
  )
) as (choices: Choice[]) => { image: Choice[]; other?: Choice[] };

const Gender: React.FunctionComponent<GenderProps> = ({
  choices = [],
  onChange,
  value,
  imageMapping,
}) => {
  const groupOfChoices = goupChoices(choices);
  const hasSortByOrder = imageMapping === genderImageMapping.ORDER;
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid
        className={classes.characters}
        container
        direction="row"
        justify="center"
        alignItems="center">
        {groupOfChoices.image.map((choice, index) => (
          <Grid item className={classes.item} key={choice.value}>
            <PureGenderImage
              onChange={onChange}
              choiceValue={choice.value}
              index={hasSortByOrder ? index + 1 : choice.value}
              value={value}
              title={choice.text}
            />
          </Grid>
        ))}
      </Grid>
      {groupOfChoices.other ? (
        <Grid container direction="column" justify="center" alignItems="center">
          {groupOfChoices.other.map((choice) => (
            // TODO create shared component
            <ChoiceButton
              key={choice.value}
              selected={value === choice.value}
              choice={choice}
              onChange={onChange}
              setPosition={onChange}
            />
          ))}
        </Grid>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default Gender;
