import { filter, has, reduce, assocPath, append, pipe, prop } from 'ramda';
import { propNotEq } from 'ramda-adjunct';
import { flatQuestionTreeWithPaths } from 'xperience-model-management';
import { Choice } from '../../elementTypes/ElementTypeInterfaces';
import { Choices } from '../../surveyCollector/SurveyCollector';

const hasChoices = has('choices');
const notHidden = propNotEq('hidden', true);
const filterHiddenChoices = pipe<{ choices: Choice[] }, Choice[], Choice[]>(
  prop('choices'),
  filter(notHidden)
);

const getChoicePath = pipe(
  // @ts-ignore
  prop('path'),
  // @ts-expect-error fix types
  append('choices')
);

const filterChoicesReducer = (acc, question) => {
  if (hasChoices(question)) {
    return assocPath(
      // @ts-expect-error fix types
      getChoicePath(question),
      filterHiddenChoices(question),
      acc
    );
  }
  return acc;
};

export const removeHiddenChoices = (survey) => {
  const flattenedQuestionsTree = flatQuestionTreeWithPaths(survey.definition);

  const currentDefinition = reduce(
    filterChoicesReducer,
    survey.definition,
    flattenedQuestionsTree
  );

  return {
    ...survey,
    definition: currentDefinition,
  };
};
