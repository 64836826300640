import * as React from 'react';

import { Rating } from './Rating';
import { StarRatingProps } from './ElementTypeInterfaces';

export const StarRating: React.FunctionComponent<StarRatingProps> = (props) => {
  return <Rating {...props} variant={'star'} />;
};

StarRating.defaultProps = {
  count: 5,
};
