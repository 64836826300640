import * as React from 'react';
import { Dialog, DialogTitle, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minHeight: 200,
    minWidth: 200,
  },
}));

interface EscapeQuestionDialogInnerProps {
  isOpen: boolean;
  onClose: () => void;
  saveDialogText: string;
}

const EscapeQuestionDialog: React.FunctionComponent<EscapeQuestionDialogInnerProps> = (
  props
) => {
  const { isOpen, onClose, saveDialogText } = props;
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}>
      <>
        {/*
  // @ts-ignore */}
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          {saveDialogText}
        </DialogTitle>
      </>
    </Dialog>
  );
};

export default EscapeQuestionDialog;
