import { Survey } from '../../surveyCollector/SurveyCollector';
import { getBeforeExitPaths } from '../customLogic/beforeExitQuestionProcessing';
import { assocPath, lensProp, reduce, over, pipe } from 'ramda';

const definitionLens = lensProp('definition');

function setBeforeQuestionProperties(survey, paths) {
  return over(
    definitionLens,
    pipe<Survey, Survey, Survey, Survey>(
      assocPath(
        [...paths.escapeQuestionPath, 'hookOptions', 'jumpScreenPath'],
        paths.jumpScreenPath
      ),
      assocPath(
        [...paths.jumpQuestionPath, 'hookOptions', 'escapeQuestionEvaluation'],
        true
      ),
      assocPath(
        [...paths.jumpQuestionPath, 'hookOptions', 'noBackWhenExit'],
        true
      )
    ),
    survey
  );
}

export const processBeforeExitQuestion = (survey: Survey): Survey => {
  const exitPaths = getBeforeExitPaths(survey.definition);
  return reduce(
    (acc, paths) => (!paths ? acc : setBeforeQuestionProperties(acc, paths)),
    survey,
    exitPaths
  );
};
