import i18next from 'i18next';
import { reactI18nextModule } from 'react-i18next';

if (process.env.NODE_ENV !== 'test') {
  // there is some problem with using i18next in test env

  i18next
    .use(reactI18nextModule) // if not using I18nextProvider
    // tslint:disable-next-line:no-floating-promises
    .init({
      debug: process.env.NODE_ENV === 'development',

      fallbackLng: 'en_US',
      lng: 'en_US',

      interpolation: {
        escapeValue: false, // not needed for react!!
      },

      // react i18next special options (optional)
      react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
      },
    });
}

export default i18next;
