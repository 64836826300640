import * as R from "ramda";
import { QuestionEvent, ScreenElementInstance, SurveyScreen } from "./SurveyCollector";

export const screenControlledByUser = screen => !screen.processCollectedData

export const getElementIds = R.pipe<SurveyScreen, ScreenElementInstance[], string[]>(
  R.prop('elements'),
  R.map(R.prop('id')),
);

export const questionIdsToEvents = ([oldQuestions, newQuestions]: [any, any]): QuestionEvent[] => {

  const now = new Date();
  return [
    ...R.difference(oldQuestions, newQuestions).map((id): QuestionEvent => ({
      eventType: 'QUESTION_HIDDEN',
      questionId: id as string,
      timestamp: now.toISOString()
    })),
    ...R.difference(newQuestions, oldQuestions).map((id): QuestionEvent => ({
      eventType: 'QUESTION_DISPLAYED',
      questionId: id as string,
      timestamp: now.toISOString()
    })),
  ]
};
