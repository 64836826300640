import * as React from 'react';
import { ValidationResult } from '../surveyCollector/SurveyCollector';
import { FormHelperText, makeStyles, Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) => ({
  text: {
    borderBottom: '4px solid red',
    fontSize: '1.3125rem',
    paddingBottom: 4,
    textAlign: 'center',
    width: '100%',
  },
}));

const QuestionError: React.FunctionComponent<ValidationResult> = ({
  message,
}) => {
  const classes = useStyles();

  return (
    <FormHelperText className={classes.text} error data-test-id="QuestionError">
      {message}
    </FormHelperText>
  );
};

export default QuestionError;
