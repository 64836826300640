import 'react-app-polyfill/ie11';
import '@babel/polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './fonts/fonts.css';
import { loadSurveyByLocation } from './surveyCollector/surveyLoading';
import { loadConfig } from './config';
import { goToFillInBlocked } from './uiState/states';

loadConfig()
  .then((config) => {
    loadSurveyByLocation(config, location.href);
    ReactDOM.render(<App />, document.getElementById('root'));
  })
  .catch((error) => {
    console.error(error);

    goToFillInBlocked('Unexpected error');
    ReactDOM.render(<App />, document.getElementById('root'));
  });

/**
 * reší scrolling na aktivní prvek na android zařízeních při zobrazení klávesnice
 * https://gitlab.commity.cz/skoda/xperience/issues/234
 */
if (/Android/.test(navigator.appVersion)) {
  window.addEventListener('resize', function () {
    if (
      document.activeElement &&
      (document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA')
    ) {
      window.setTimeout(function () {
        if (!document.activeElement) {
          return;
        }

        const textInputElementRoot =
          (document.activeElement.closest &&
            document.activeElement.closest('[data-textInput-scroll-point]')) ||
          document.activeElement;

        textInputElementRoot.scrollIntoView();
      }, 10);
    }
  });
}
