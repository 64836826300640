import * as React from 'react';
import { componentFromStream } from 'recompose';
import { map } from 'rxjs/operators';
import { FillInBlocked } from './stateComponents/FillInBlocked';
import { Loading } from './stateComponents/Loading';
import { ShowFinalScreen } from './stateComponents/ShowFinalScreen';
import { ShowScreen } from './stateComponents/ShowScreen';
import { State, state$, StateType } from './states';
import { DisplayConnectionError } from './stateComponents/DisplayConnectionError';
import { useObservableState } from 'observable-hooks';

export const UIState = () => {
  const [state] = useObservableState<State>(() => {
    return state$.pipe(
      map<State, any>((state) => {
        switch (state.type) {
          default:
          case StateType.Loading:
            return <Loading />;

          case StateType.FillInBlocked:
            return (
              <FillInBlocked
                code={state.code}
                reason={state.reason}
                redirect={state.redirect}
                redirectLink={state.redirectLink}
                redirectSeconds={state.redirectSeconds}
              />
            );

          case StateType.ScreenProcessing:
            return <Loading />;

          case StateType.ScreenDisplayed:
            return (
              <ShowScreen
                screen={state.screen}
                surveyMetadata={state.surveyMetadata}
              />
            );

          case StateType.ConnectionErrorDisplayed:
            return <DisplayConnectionError />;

          case StateType.FinalScreenDisplayed:
            return <ShowFinalScreen screen={state.screen} />;
        }
      })
    );
  });

  if (!state) {
    return null;
  }

  return <>{state}</>;
};
