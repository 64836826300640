import { differenceInYears } from 'date-fns';
import {
  __,
  all,
  always,
  any,
  apply,
  call,
  complement,
  cond,
  curry,
  equals,
  gt,
  gte,
  ifElse,
  indexOf,
  intersection,
  isNil,
  lt,
  lte,
  map,
  not,
  pipe,
  prop,
  propEq,
  split,
  unapply,
} from 'ramda';
import {
  allEqualTo,
  ensureArray,
  isArray,
  isFalse,
  isString,
  isTrue,
} from 'ramda-adjunct';

const isNotEmptyListInFunctionAll = curry((fn, list) =>
  list.length > 0 ? all(fn, list) : false
) as any;

const extensionOfIntConditions = (operation, callFn) => (value1, value2) => {
  const [array, int] = isArray(value1) ? [value1, value2] : [value2, value1];
  return callFn(operation(__, int), array);
};
const transformToArray = ifElse(
  isString,
  pipe(split(','), map(Number)),
  ensureArray
);

const reusableConditions = {
  lessThan: lt,
  lessThanOrEqual: lte,
  greaterThan: gt,
  greaterThanOrEqual: gte,
};

const operationMapping = {
  ...reusableConditions,
  or: unapply(any(equals(true))),
  // @ts-expect-error FIX types
  and: unapply(allEqualTo(true)),
  lessThen: lt /* @deprecated, use lessThan */,
  lessThenOrEqual: lte /* @deprecated, use lessThanOrEqual */,
  greaterThen: gt /* @deprecated, use greaterThan */,
  greaterThenOrEqual: gte /* @deprecated, use greaterThanOrEqual */,
  not: not,
  equal: equals,
  isTrue: isTrue,
  isFalse: isFalse,
  notEqual: complement(equals),
  oneOf: pipe(indexOf, complement(equals(-1))),
  responded: complement(isNil),
  olderThen: (date, value) =>
    gt(
      differenceInYears(new Date(), date),
      value
    ) /* @deprecated, use olderThan */,
  olderThan: (date, value) => gte(differenceInYears(new Date(), date), value),
  sameArrays: (value1, value2) => {
    const [v1, v2] = [value1, value2].map(transformToArray);
    if (v1.length === v2.length) {
      return intersection(v1, v2).length === v1.length;
    }
    return false;
  },
  someConformityInArrays: (value1, value2) => {
    const [v1, v2] = [value1, value2].map(transformToArray);
    return intersection(v1, v2).length > 0;
  },
  differentArrays: (value1, value2) => {
    const [v1, v2] = [value1, value2].map(transformToArray);
    return intersection(v1, v2).length === 0;
  },
  allLessThan: extensionOfIntConditions(
    reusableConditions.lessThan,
    call(isNotEmptyListInFunctionAll)
  ),
  someLessThan: extensionOfIntConditions(
    reusableConditions.lessThan,
    call(any)
  ),
  allLessThanOrEqual: extensionOfIntConditions(
    reusableConditions.lessThanOrEqual,
    call(isNotEmptyListInFunctionAll)
  ),
  someLessThanOrEqual: extensionOfIntConditions(
    reusableConditions.lessThanOrEqual,
    call(any)
  ),
  allGreaterThan: extensionOfIntConditions(
    reusableConditions.greaterThan,
    call(isNotEmptyListInFunctionAll)
  ),
  someGreaterThan: extensionOfIntConditions(
    reusableConditions.greaterThan,
    call(any)
  ),
  allGreaterThanOrEqual: extensionOfIntConditions(
    reusableConditions.greaterThanOrEqual,
    call(isNotEmptyListInFunctionAll)
  ),
  someGreaterThanOrEqual: extensionOfIntConditions(
    reusableConditions.greaterThanOrEqual,
    call(any)
  ),
};

type Operation = keyof typeof operationMapping;

type ExpressionArgumentValue = {
  type: 'value' | 'variable';
  value: any;
};

export type ExpressionFunction = {
  type?: 'function';
  operation: Operation;
  arguments: ExpressionArgument[];
};

type ExpressionArgument = ExpressionArgumentValue | ExpressionFunction;

const isValue = propEq('type', 'value');
const isVariable = propEq('type', 'variable');
const isFunction = propEq('type', 'function');

const argumentAsFunction: (ExpressionFunction) => Function = cond([
  // @ts-expect-error FIX types
  [isValue, (arg) => always(arg.value)],
  // @ts-expect-error FIX types
  [isVariable, (arg) => prop(arg.value)],
  // @ts-expect-error FIX types
  [isFunction, (arg) => expressionToFunction(arg)],
]);

export const expressionToFunction = (
  expression: ExpressionFunction
): Function => {
  const func = operationMapping[expression.operation] as (
    ...args: any[]
  ) => Boolean;
  const argsFunctions = expression.arguments.map(argumentAsFunction);

  return (variables) =>
    apply(
      func,
      argsFunctions.map((f) => f(variables))
    );
};
