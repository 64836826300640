export const saveSurveyData = (surveyParams, surveyData) =>
  localStorage.setItem(
    `${surveyParams.surveyId}-survey-data`,
    JSON.stringify(surveyData)
  );

export const cleanSurveyData = surveyParams =>
  localStorage.removeItem(`${surveyParams.surveyId}-survey-data`);

export const loadSurveyData = surveyParams => {
  try {
    const json =
      localStorage.getItem(`${surveyParams.surveyId}-survey-data`) || '{}';
    return JSON.parse(json);
  } catch (e) {
    return {};
  }
};
