import * as React from 'react';
import {
  Button,
  createStyles,
  Icon,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import WifiOff from '@material-ui/icons/WifiOff';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      maxWidth: '800px',
    },
    icon: {
      maxWidth: '150px',
      width: '30%',
      height: '30%',
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);

type ConnectionErrorProps = {
  onTrySendClick: () => void;
};

type InnerProps = ConnectionErrorProps;

const ConnectionError: React.FunctionComponent<InnerProps> = ({
  onTrySendClick,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <WifiOff className={classes.icon} />
      <Typography variant="h4" color="textPrimary">
        <Trans i18nKey="ui.connectionError.message">
          Failed to send questionnaire data, probably it is Internet connection
          error.
        </Trans>
      </Typography>
      <Button
        onClick={onTrySendClick}
        className={classes.button}
        variant="outlined"
        size="large"
        color="primary">
        <Typography variant="h4" color="primary">
          <Trans i18nKey="ui.connectionError.resendButton">Try again</Trans>
          &nbsp;
        </Typography>
        <Icon>send</Icon>
      </Button>
    </div>
  );
};

export default ConnectionError;
