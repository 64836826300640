import {
  always,
  append,
  dec,
  defaultTo,
  equals,
  inc,
  is,
  length,
  path,
  pipe,
} from 'ramda';
import {
  countElementsInSection,
  getChildPath,
  getParentSectionPath,
  getParentSectionPathIfLastIsZero,
  isFirstItemInSection,
  isItemLastInSection,
  isTypeEscape,
  isTypeIntro,
  isTypeOutro,
  isTypeSection,
  updateLastPathItem,
} from './pathFunctions';
import { Questionnaire, SurveyScreenPath } from './SurveyCollector';

export const getStartPath = always([0] as SurveyScreenPath);

export function getNextScreenPath(
  questionnaire: Questionnaire,
  currentScreenPath?: SurveyScreenPath
): SurveyScreenPath {
  if (!currentScreenPath) {
    return getStartPath();
  }

  if (isTypeOutro(currentScreenPath, questionnaire)) {
    return currentScreenPath;
  }

  const nextScreenPath = updateLastPathItem(inc, currentScreenPath);

  if (isItemLastInSection(currentScreenPath, questionnaire)) {
    return getNextScreenPath(
      questionnaire,
      getParentSectionPath(currentScreenPath)
    );
  }

  if (isTypeSection(nextScreenPath, questionnaire)) {
    return getNextScreenWhenSection(
      getChildPath(nextScreenPath),
      questionnaire
    );
  }

  return nextScreenPath;
}

export function getPreviousScreenPath(
  questionnaire: Questionnaire,
  currentScreenPath?: SurveyScreenPath
): SurveyScreenPath {
  if (!currentScreenPath) {
    return [0];
  }

  if (
    isTypeOutro(currentScreenPath, questionnaire) ||
    isTypeEscape(currentScreenPath, questionnaire)
  ) {
    return currentScreenPath;
  }

  const actualScreenPath = getParentSectionPathIfLastIsZero(currentScreenPath);
  if (isFirstItemInSection(actualScreenPath)) {
    return getPreviousScreenPath(questionnaire, actualScreenPath);
  }

  const previousScreenPath = updateLastPathItem(dec, actualScreenPath);
  const previousScreen = path(previousScreenPath, questionnaire);

  if (!previousScreen || length(previousScreenPath) === 0) {
    return [0];
  }

  if (isTypeIntro(previousScreenPath, questionnaire)) {
    return actualScreenPath;
  }

  if (isTypeSection(previousScreenPath, questionnaire)) {
    return getPreviousScreenWhenSection(previousScreenPath, questionnaire);
  }

  return previousScreenPath;
}

function getNextScreenWhenSection(
  currentScreenPath: SurveyScreenPath,
  questionnaire: Questionnaire
): SurveyScreenPath {
  if (isTypeSection(currentScreenPath, questionnaire)) {
    return getNextScreenWhenSection(
      getChildPath(currentScreenPath),
      questionnaire
    );
  }

  return currentScreenPath;
}

function getPreviousScreenWhenSection(
  currentScreenPath: SurveyScreenPath,
  questionnaire: Questionnaire
): SurveyScreenPath {
  const addLastChildElement: (
    path: SurveyScreenPath
  ) => SurveyScreenPath = pipe(
    append('elements'),
    (actualPath: SurveyScreenPath) => {
      return append(
        dec(countElementsInSection(actualPath, questionnaire)),
        actualPath
      );
    }
  );

  if (isTypeSection(currentScreenPath, questionnaire)) {
    return getPreviousScreenWhenSection(
      addLastChildElement(currentScreenPath),
      questionnaire
    );
  }

  return currentScreenPath;
}

export const getEndScreenPath = (
  questionnaire: Questionnaire,
  currentScreenPath?: SurveyScreenPath
) => {
  // TODO consider check question type
  return [questionnaire.length - 1];
};

export const pathComparator = (path1, path2) => {
  if (equals(path1, path2)) {
    return 0;
  }

  const path1Numbers = defaultTo([0], path1).filter(is(Number));
  const path2Numbers = defaultTo([0], path2).filter(is(Number));

  const length = Math.max(path1Numbers.length, path2Numbers.length);
  for (let i = 0; i < length; i++) {
    const n1 = path1Numbers[i] || 0;
    const n2 = path2Numbers[i] || 0;
    if (n1 > n2) {
      return -1;
    } else if (n1 < n2) {
      return 1;
    }
  }

  return 0;
};
