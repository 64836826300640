import { findIndex, lensProp, over, path, remove } from 'ramda';
import { isIntro } from 'xperience-model-management';
import { Survey } from '../../surveyCollector/SurveyCollector';

export const removeHiddenIntro = (survey: Survey) => {
  const introIndex = findIndex(isIntro, survey.definition);
  if (introIndex !== -1) {
    if (
      path([introIndex, 'componentProps', 'hiddenIntro'], survey.definition) ===
      true
    ) {
      return over(lensProp('definition'), remove(introIndex, 1), survey);
    }
  }
  return survey;
};
