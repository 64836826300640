/**
 * https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference/46514247#46514247
 */

export function getFirstBrowserLanguage() {
  const navigator = window.navigator;
  const browserLanguagePropertyKeys = [
    'language',
    'browserLanguage',
    'systemLanguage',
    'userLanguage',
  ];
  let shortLanguage: unknown = null;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(navigator.languages)) {
    for (let i = 0; i < navigator.languages.length; i++) {
      const language = navigator.languages[i];
      const len = language.length;
      if (!shortLanguage && len) {
        shortLanguage = language;
      }
      if (language && len >= 2) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
    const language = navigator[browserLanguagePropertyKeys[i]];
    const len = language.length;
    if (!shortLanguage && len) {
      shortLanguage = language;
    }
    if (language && len >= 2) {
      return language;
    }
  }

  return shortLanguage;
}
