/* tslint:disable:no-unnecessary-type-assertion */

import QuestionError from '../../elementTypes/QuestionError';
import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    paddingTop: 1,
  },
}));

export type ValidationResult = {
  message: string;
};

export interface ErrorContainerProps {
  validationResult: ValidationResult[];
  className: string;
}

const isInViewPort = (bounding) =>
  bounding.top >= 0 &&
  bounding.left >= 0 &&
  bounding.right <=
    (window.innerWidth || document.documentElement!.clientWidth || 0) &&
  bounding.bottom <=
    (window.innerHeight || document.documentElement!.clientHeight || 0);

class ErrorContainer extends React.PureComponent<ErrorContainerProps> {
  private element: any;

  componentDidMount(): void {
    if (!this.element || !this.element.getBoundingClientRect) {
      return;
    }

    const elementBounding = this.element.getBoundingClientRect();
    if (!isInViewPort(elementBounding)) {
      const scrollContainer = document.querySelector('#screenQuestion');
      if (!scrollContainer) {
        return;
      }

      scrollContainer.scrollTop = elementBounding.top;
    }
  }

  setElementRef = (ref) => {
    this.element = ref;
  };

  render() {
    const { className, validationResult } = this.props;

    return (
      <div style={{ paddingTop: 1 }} ref={this.setElementRef}>
        {validationResult.map((item, index) => (
          <QuestionError key={index} message={item.message} />
        ))}
      </div>
    );
  }
}

export default ErrorContainer;
