import {
  append,
  concat,
  dropLast,
  filter,
  findIndex,
  forEachObjIndexed,
  inc,
  length,
  pipe,
  prop,
  propEq,
} from 'ramda';
import {
  isTypeQuestion,
  Questionnaire,
  SurveyProgress,
  SurveyProgressData,
  SurveyScreenPath,
} from './SurveyCollector';

export type ProgressMode = 'minimal' | 'full';

export type QuestionPath = {
  afterEscape: boolean;
  path: SurveyScreenPath;
};

const currentPosition = (
  surveyPath: SurveyScreenPath,
  questionPaths: QuestionPath[]
): number =>
  pipe<SurveyScreenPath, (p: QuestionPath) => boolean, number, number>(
    propEq('path'),
    (comparator) => findIndex(comparator, questionPaths),
    inc
  )(surveyPath);

const isAfterEscapeQuestion = propEq('afterEscape', false);
const isEscapeQuestion = propEq('type', 'escape');
const isPage = propEq('type', 'page');
const hasElements = prop('elements');

const getRecursiveQuestionPaths = (
  questionnaire: Questionnaire
): QuestionPath[] => {
  const questionPath: QuestionPath[] = [];
  let afterEscape = false;

  const nestedPaths = (
    obj,
    path: SurveyScreenPath = [],
    parentIsPage: Boolean = false
  ) => {
    forEachObjIndexed((x, i) => {
      const isEscape = isEscapeQuestion(x);
      if (isTypeQuestion(x.type) || isEscape) {
        questionPath.push({
          afterEscape,
          path: parentIsPage ? dropLast(1, path) : append(Number(i), path),
        });
      }
      if (isEscape) {
        afterEscape = true;
      }
      if (hasElements(x)) {
        nestedPaths(
          x.elements,
          concat(path, [Number(i), 'elements']),
          isPage(x)
        );
      }
    }, obj);
  };

  nestedPaths(questionnaire);

  return questionPath;
};

export function getSurveyProgress(
  questionnaire: Questionnaire,
  surveyPath: SurveyScreenPath,
  mode: ProgressMode = 'minimal'
): SurveyProgressData {
  const questionPaths = getRecursiveQuestionPaths(questionnaire);
  const numberQuestionsBeforeEscape = pipe<
    QuestionPath[],
    QuestionPath[],
    number
  >(
    filter(isAfterEscapeQuestion),
    length
  )(questionPaths);

  const current = currentPosition(surveyPath, questionPaths);
  const total =
    mode === 'full' || current > numberQuestionsBeforeEscape
      ? length(questionPaths)
      : numberQuestionsBeforeEscape;

  return {
    total,
    current,
  };
}
