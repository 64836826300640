import {
  adjust,
  concat,
  dec,
  dropLast,
  equals,
  last,
  length,
  lt,
  map,
  path,
  pathEq,
  pipe,
  take,
  unless,
} from 'ramda';
import { allEqual } from 'ramda-adjunct';
import {
  Questionnaire,
  SurveyScreenPath,
  SurveyScreenPathElements,
} from './SurveyCollector';

export const isTypeOutro = (
  screenPath: SurveyScreenPath,
  questionnaire: Questionnaire
) => pathEq([...screenPath, 'type'], 'outro', questionnaire);

export const isTypeIntro = (
  screenPath: SurveyScreenPath,
  questionnaire: Questionnaire
) => pathEq([...screenPath, 'type'], 'intro', questionnaire);

export const isTypeEscape = (
  screenPath: SurveyScreenPath,
  questionnaire: Questionnaire
) => pathEq([...screenPath, 'type'], 'escape', questionnaire);

export const isTypeSection = (
  screenPath: SurveyScreenPath,
  questionnaire: Questionnaire
) => pathEq([...screenPath, 'type'], 'section', questionnaire);

export const lastItemIndex: (path: SurveyScreenPath) => number = pipe(
  length,
  dec
);

export const currentItemIndex: (screenPath: SurveyScreenPath) => number = last;

export const updateLastPathItem = (
  f: (a: any) => any,
  screenPath: SurveyScreenPath
) => adjust(lastItemIndex(screenPath), f, screenPath);

export const countElementsInSection = (
  screenPath: SurveyScreenPath,
  questionnaire: Questionnaire
): number =>
  // @ts-expect-error fix types
  pipe<Questionnaire, any[], number>(
    // @ts-ignore
    path<any[]>(screenPath),
    length
    // @ts-ignore
  )(questionnaire);

export const isItemLastInSection = (
  screenPath: SurveyScreenPath,
  questionnaire: Questionnaire
) => {
  const countElements = countElementsInSection(
    dropLast(1, screenPath),
    questionnaire
  );

  return currentItemIndex(screenPath) >= countElements - 1;
};

export const getChildPath = (screenPath: SurveyScreenPath) =>
  concat(screenPath, ['elements', 0]);
export const getParentSectionPath = dropLast<SurveyScreenPathElements>(2) as (
  element: SurveyScreenPath
) => SurveyScreenPath;
export const getParentSectionPathIfLastIsZero = unless<
  SurveyScreenPath,
  () => SurveyScreenPath
>(
  // @ts-ignore
  currentItemIndex,
  getParentSectionPath
) as (path: SurveyScreenPath) => SurveyScreenPath;
export const isFirstItemInSection = (screenPath: SurveyScreenPath) =>
  last(screenPath) === 0;
export const isParentScreenTypeSection = (
  screenPath: SurveyScreenPath,
  questionnaire: Questionnaire
) => {
  return isTypeSection(getParentSectionPath(screenPath), questionnaire);
};
export const isPathNested: (path: SurveyScreenPath) => Boolean = pipe(
  length,
  lt(1)
);
export const areArraysEqualLength = (...screenPaths: SurveyScreenPath[]) => {
  // @ts-ignore
  return allEqual(map(length, screenPaths));
};
export const areParentSectionPathsEqual = (
  ...screenPaths: SurveyScreenPath[]
) => {
  // @ts-ignore
  return allEqual(map(getParentSectionPath, screenPaths));
};
export const isRootPathSame = (
  parentScreenPath: SurveyScreenPath,
  previousScreenPath: SurveyScreenPath
) => {
  return equals(
    parentScreenPath,
    take(length(parentScreenPath), previousScreenPath)
  );
};
export const leaveSection = (
  previousScreenPath: SurveyScreenPath,
  currentScreenPath: SurveyScreenPath
) => {
  return length(previousScreenPath) > length(currentScreenPath);
};
export const fromSectionToSection = (
  previousScreenPath: SurveyScreenPath,
  currentScreenPath: SurveyScreenPath
) => {
  return (
    areArraysEqualLength(previousScreenPath, currentScreenPath) &&
    !areParentSectionPathsEqual(currentScreenPath, previousScreenPath)
  );
};
